import React from 'react';
import { ToggleButton, Typography, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CustomToggleButton = ({
    value,
    selected,
    onChange,
    label,
    tooltipText,
    tooltipPlacement = "top",
    tooltipDelay = 200,
    sx = {},
}) => {
    const theme = useTheme();

    return (
        <ToggleButton
            value={value}
            selected={selected}
            onChange={onChange}
            size="small"
            sx={{
                height: '24px',
                minWidth: '56px',
                position: 'relative',
                textTransform: 'none',
                border: '1px solid #e0e0e0',
                borderRadius: '12px',
                padding: '2px',
                margin: '4px',
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'center',
                justifyContent: 'center',
                transition: 'all 0.2s ease-in-out',
                overflow: 'hidden',
                boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)',

                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '2px',
                    left: selected ? 'calc(100% - 22px)' : '2px',
                    width: '18px',
                    height: '18px',
                    borderRadius: '50%',
                    backgroundColor: selected ? '#fff' : '#bdbdbd',
                    transition: 'all 0.2s ease-in-out',
                    boxShadow: '0 1px 2px rgba(0,0,0,0.15)',
                    zIndex: 1,
                },

                '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                        borderColor: theme.palette.primary.dark,
                    },
                    '&::before': {
                        transform: 'scale(0.95)',
                    },
                },

                '&:not(.Mui-selected)': {
                    backgroundColor: '#f0f0f0',
                    '&:hover': {
                        backgroundColor: '#e5e5e5',
                        '&::before': {
                            backgroundColor: '#999',
                            transform: 'scale(0.95)',
                        },
                    },
                },

                '&:active::before': {
                    transform: 'scale(0.9)',
                },
                ...sx
            }}
        >
            <Tooltip
                title={tooltipText || label}
                placement={tooltipPlacement}
                enterDelay={tooltipDelay}
            >
                <Typography
                    sx={{
                        fontSize: '0.8rem',
                        fontWeight: '600',
                        letterSpacing: '0.01em',
                        color: selected ? '#fff' : '#555',
                        position: 'relative',
                        zIndex: 2,
                        transition: 'all 0.2s ease-in-out',
                        userSelect: 'none',
                        ml: selected ? 0 : '14px',
                        mr: selected ? '14px' : 0,
                        opacity: 0.9,
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        '&:hover': {
                            opacity: 1,
                        },
                    }}
                >
                    {label}
                </Typography>
            </Tooltip>
        </ToggleButton>
    );
};

export default CustomToggleButton;
