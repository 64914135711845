import { Alert, Autocomplete, Box, Button, Dialog, DialogContentText, FormGroup, Snackbar, Stack, TextField, ThemeProvider, Typography ,Tab} from "@mui/material";
import { theme } from "../Theme";
import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { allocateSocialDomain, getSocialChildren, getSocialsBank, uploadSocialDomains, getSocialsBankPaginated } from "../api";
import { Add, DeleteOutline, LocalMallOutlined, PanToolAltOutlined, ShortcutOutlined, FileDownloadOutlined, Person } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { isAdmin, isSocialManager } from "../msalService";
import UploadCSVDialog from "../UploadCSVDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useUser } from "../UserProvider";
import UploadCSVDialogSocialDomains from "../UploadCSVDialogSocialDomains";
import { MaterialReactTable,useMaterialReactTable } from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Sort as SortIcon } from "@mui/icons-material";
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function ClaimHub() {
  let [domainso, setDomainso] = useState([]);

  let [val, setVal] = useState("");
  const [tvalue, setTvalue] = useState("Ltd");
  let [psize, setPsize] = useState(50);
  let [idds, setIdds] = React.useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAllocate, setOpenAllocate] = useState(false);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);
  const [domains, setDomains] = useState([]);
  const [agent, setAgent] = useState([]);
  const [children, setChildren] = useState([]);
  const { user } = useUser();
  const [ids, setIds] = useState([]);
  const [b, setB] = useState(false);
  let allotted = useRef([0, 0]);
  const [allocating, setAllocating] = useState(false);

  // Add new state variables for pagination, sorting, etc.
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50, });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [globalSearch, setGlobalSearch] = useState('');
  const [hubType, setHubType] = useState('ltd'); // 'owned' or 'ltd'
  const [checked, setChecked] = React.useState(true);

  const handleAgentChange = (event) => {
    setAgent(event.target.value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
  };

  useEffect(() => {
    console.log(domains);
    if (domains && domains.length > 0)
      uploadSocialDomains(domains)
        .then((res) => {
          setUpsuc(true);
          setDomains([]);
          setB(!b);
        })
        .catch((err) => {
          console.log(err);
          setUpfail(true);
        });
  }, [domains]);

  // Add a new useEffect for getSocialChildren that only runs once
  useEffect(() => {
    getSocialChildren()
      .then((res) => {
        if (res && res.data) {
          setChildren(res.data);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch social children:', error);
        setChildren([]);
      });
  }, []); // Empty dependency array means this only runs once on mount

  const columns = useMemo(() => [
    {
      accessorKey: "dateOfUpload",
      header: "Date",
      size: 100,
      accessorFn: (row) => {
        if (!row.dateOfUpload) return "N/A";
        const date = new Date(row.dateOfUpload);
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('en-US', { month: 'short' });
        return `${day}-${month}`;
      },
    },
    {
      accessorKey: "domain",
      header: "Domain",
      size: 210,
      accessorFn: (row) => {
        return row.domain;
      },
    },
    {
      accessorKey: "deadline",
      header: "Deadline",
      size: 100,
      accessorFn: (row) => {
        if (!row.deadline) return "N/A";
        const date = new Date(row.deadline);
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('en-US', { month: 'short' });
        return `${day}-${month}`;
      },
    },
    {
      accessorKey: "edate",
      header: "E-Date",
      size: 100,
      accessorFn: (row) => {
        if (!row.edate) return "N/A";
        const date = new Date(row.edate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('en-US', { month: 'short' });
        return `${day}-${month}`;
      },
    },
    {
      accessorKey: "list",
      header: "List",
      size: 70,
      accessorFn: (row) => {
        return row.list;
      },
    },
    {
      accessorKey: "uploader",
      header: "Uploaded by",
      size: 110,
      Cell: ({ row }) => {
        if (!row.original.uploader) return "N/A";
        
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Person
              sx={{
                color: '#8797A8',
                fontSize: '1.2rem'
              }}
            />
            <Typography
              variant="caption"
              sx={{
                fontSize: '0.7rem',
                fontStyle: 'italic'
              }}
            >
              {`${row.original.uploader.firstName} ${row.original.uploader.lastName}`}
            </Typography>
          </Box>
        );
      },
    },
  ], []);

  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  // Update fetchData to only handle getSocialsBankPaginated
  const fetchData = useCallback((
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);

    // Transform filters into expected format
    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    // Transform sorting into expected format
    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    // Make API call with all parameters
    getSocialsBankPaginated({
      page: paginationRules.pageIndex,
      size: paginationRules.pageSize,
      sort: transformedSorting,
      filter: transformedFilters,
      search: searchQuery,
      hub: hubType
    })
      .then((response) => {
        if (response && response.data) {
          setDomainso(response.data.content);
          setTotalRowCount(response.data.totalElements);
        }
      })
      .catch(error => {
        console.error('API call failed:', error);
        setDomainso([]);
        setTotalRowCount(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [columnFilters, sorting, pagination, globalSearch, hubType]);

  useEffect(() => {
    fetchData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    columnFilters,
    globalSearch,
    hubType,
    b // Keep the b dependency for refetching after actions
  ]);

  const handleExportData = useCallback((table) => {
    try {
      // Get selected rows from the table instance
      const selectedRows = table.getSelectedRowModel().rows;
      
      if (!selectedRows || selectedRows.length === 0) {
        console.warn('No rows selected for export');
        return;
      }

      // Only export columns that are shown in the table
      const exportColumns = [
        'dateOfUpload',
        'domain',
        'deadline',
        'edate',
        'list',
        'uploader'
      ];

      // Create header with display names from columns definition
      const header = exportColumns.map(colKey => {
        const column = columns.find(col => col.accessorKey === colKey);
        return column?.header || colKey;
      }).join(',') + '\n';

      // Create CSV content with modified uploader format
      const csvContent = selectedRows.map(row => {
        return exportColumns.map(colKey => {
          if (colKey === 'uploader') {
            return row.original.uploader?.firstName || 'N/A';
          }
          
          const column = columns.find(col => col.accessorKey === colKey);
          let value = column?.accessorFn ? column.accessorFn(row.original) : row.original[colKey];
          
          if (value === null || value === undefined) return '';
          if (typeof value === 'string' && value.includes(',')) return `"${value}"`;
          return value;
        }).join(',');
      }).join('\n');

      const csv = header + csvContent;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      // Format date as dd-MMM
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const month = today.toLocaleString('en-US', { month: 'short' });
      const formattedDate = `${dd}-${month}`;

      link.setAttribute('href', url);
      link.setAttribute('download', `claimHubDomains-${formattedDate}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Export error:', error);
      setUpfail(true);
    }
  }, [columns]);


  const domainTable = useMaterialReactTable({
    columns,
    data: domainso,
    initialState: {
      density: 'compact',
      pagination: { pageSize: 50, pageIndex: 0 },
      sorting: [],
    },
    enableColumnFilterModes: true,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    enableGlobalFilter: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,
    
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row' spacing={1} alignItems='center' width="100%">
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenFilterDialog(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          startIcon={<SortIcon />}
          onClick={() => setOpenSortDialog(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Sort
        </Button>
        <Button
          disabled={table.getSelectedRowModel()?.rows?.length === 0 || allocating}
          variant="outlined"
          startIcon={<LocalMallOutlined />}
          onClick={() => {
            setAllocating(true);
            allocateSocialDomain(
              table.getSelectedRowModel().rows.map(row => row.original.id), 
              user.email
            )
              .then((res) => {
                allotted.current = res.data;
                setUpsuc1(true);
                setB(!b);
                table.resetRowSelection();
                setAgent("");
                setAllocating(false);
              })
              .catch((err) => {
                setUpfail1(true);
                setAllocating(false);
              });
          }}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          {allocating ? "Claiming..." : "Claim"}
        </Button>
        {isSocialManager() && (
          <Button
            disabled={table.getSelectedRowModel()?.rows?.length === 0}
            variant="outlined"
            startIcon={<PanToolAltOutlined />}
            onClick={() => setOpenAllocate(true)}
            sx={{
              textTransform: 'none',
              backgroundColor: '#8797A8',
              color: '#FFFFFF !important',
              padding: '4px 8px',
              minWidth: 'auto',
              '&:hover': {
                backgroundColor: '#8797A8',
                opacity: 0.9,
              },
              '&.Mui-disabled': {
                backgroundColor: '#8797A8',
                opacity: 0.7,
              }
            }}
          >
            Allocate
          </Button>
        )}
        <Button
          disabled={table.getSelectedRowModel()?.rows?.length === 0}
          variant="outlined"
          startIcon={<FileDownloadOutlined />}
          onClick={() => handleExportData(table)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Export
        </Button>
        <Box flexGrow={1} />
        <TextField
          placeholder="Search domains..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSearch();
          }}
          size="small"
          sx={{
            minWidth: '100px',
            '& .MuiOutlinedInput-root': {
              height: '30px',
              fontSize: '0.875rem',
              backgroundColor: '#ffffff',
              borderRadius: '20px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#8797A8',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '20px',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ fontSize: '1.2rem', color: '#8797A8', cursor: 'pointer' }}
                  onClick={handleSearch}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    ),
    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },

    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading: loading,
      globalFilter: globalSearch
    },
  });

  // Add these handlers after your existing state declarations
  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="column" spacing={5}>
        <Stack direction="row" spacing={2}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "left",
              fontWeight: "bold",
              position: "relative",
            }}
          >
            Claim Hub
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <ToggleButtonGroup
            value={hubType}
            exclusive
            onChange={(e, newValue) => setHubType(newValue)}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 2,
              '& .MuiToggleButton-root': {
                textTransform: 'none',
                padding: '6px 12px',
                fontSize: '0.75rem',
                backgroundColor: theme.palette.grey[200],
                minHeight: 36,
                '&.Mui-selected': {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
                '&:not(:last-child)': {
                  borderRight: '1px solid #e0e0e0',
                },
              },
            }}
          >
            <ToggleButton value="owned">
              Owned Hub
            </ToggleButton>
            <ToggleButton value="ltd">
              Ltd Hub
            </ToggleButton>
          </ToggleButtonGroup>
          {isAdmin() && (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => setOpenDialog(true)}
                sx={{
                  height: 40,
                  borderRadius: 100,
                  textTransform: 'none'
                }}
              >
                Add Domains
              </Button>
              <UploadCSVDialogSocialDomains
                title="Domains"
                open={openDialog}
                handleClose={handleClose}
                setState={setDomains}
              />
            </>
          )}
        </Stack>
        <Snackbar
          open={upsuc}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => {
            setUpsuc(false);
          }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Domains Uploaded!
          </Alert>
        </Snackbar>
        <Snackbar
          open={upfail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => {
            setUpfail(false);
          }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Domain Not Uploaded!
          </Alert>
        </Snackbar>
        <Snackbar
          open={upsuc1}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => {
            setUpsuc1(false);
          }}
        >
          <Alert
            severity={allotted.current[0] == 0 ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            Allotted {allotted.current[0]}/{allotted.current[1]} domain
            {allotted.current[0] != 1 && "s"}.
          </Alert>
        </Snackbar>
        <Snackbar
          open={upfail1}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => {
            setUpfail1(false);
          }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Not Allotted!
          </Alert>
        </Snackbar>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MaterialReactTable table={domainTable} />
            <MultiFilterDialog
              open={openFilterDialog}
              onClose={() => setOpenFilterDialog(false)}
              columns={columns}
              initialFilters={columnFilters}
              onApplyFilter={handleFilterApply}
            />
            <MultiSortDialog
              open={openSortDialog}
              onClose={() => setOpenSortDialog(false)}
              columns={columns}
              initialSorting={sorting}
              onApplySort={handleSortApply}
            />
          </LocalizationProvider>
        </Box>
        <TabContext value={tvalue}>
          <Box sx={{ width: "100%" }}></Box>
          <Dialog fullWidth open={openAllocate} onClose={handleCloseAllocate}>
            <DialogTitle>
              {
                <Stack direction="row">
                  <Typography variant="h6">Allocate to Processor </Typography>{" "}
                </Stack>
              }
            </DialogTitle>
            <DialogContent>
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <DialogContentText>
                  {domainTable.getSelectedRowModel().rows.length} domain{domainTable.getSelectedRowModel().rows.length !== 1 && "s"} selected
                </DialogContentText>
              </Stack>
              <FormGroup sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="processor-label">Select Agent</InputLabel>
                    <Select
                      disabled={!checked}
                      labelId="processor-label"
                      id="processor-select"
                      value={agent}
                      label="Select Processor"
                      onChange={handleAgentChange}
                    >
                      {children.map((child) => (
                        <MenuItem key={child.email} value={child.email}>
                          {child.firstName + " " + child.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAllocate}>Cancel</Button>
              <Button
                disabled={allocating}
                variant="contained"
                onClick={() => {
                  setAllocating(true);
                  allocateSocialDomain(
                    domainTable.getSelectedRowModel().rows.map(row => row.original.id),
                    agent
                  )
                    .then((res) => {
                      allotted.current = res.data;
                      setUpsuc1(true);
                      setB(!b);
                      domainTable.resetRowSelection();
                      setAgent("");
                      handleCloseAllocate();
                      setAllocating(false);
                    })
                    .catch((err) => {
                      setUpfail1(true);
                      setAllocating(false);
                    });
                }}
              >
                Allocate
              </Button>
            </DialogActions>
          </Dialog>
        </TabContext>
      </Stack>
    </ThemeProvider>
  );
}
