import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Stack,
    TextField,
    Typography,
    Select,
    MenuItem,
    Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCSVReader, formatFileSize, readString } from "react-papaparse";
import { getGoogleSheetData } from "../api";

export default function UploadCSVDialogOwnedDomains(props) {
    const GREY = "#CCC";
    const GREY_DIM = "#686868";

    const parseCSV = (csvData) => {
        return new Promise((resolve, reject) => {
            const results = readString(csvData, {
                header: true,
                skipEmptyLines: true,
                transformHeader: header => header.toLowerCase().replace(/\s+/g, ''),
                complete: (results) => {
                    resolve(results.data);
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    };

    const styles = {
        zone: {
            alignItems: "center",
            border: `2px dashed ${GREY}`,
            borderRadius: '8px',
            display: "flex",
            flexDirection: "column",
            height: '138px',
            width: "100%",
            justifyContent: "center",
            padding: '16px',
            fontSize: '0.875rem',
            backgroundColor: '#fff',
            transition: 'all 0.2s ease-in-out',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#f8f9fa',
                borderColor: '#1976d2',
                borderStyle: 'dashed'
            }
        },
        zoneHover: {
            borderColor: GREY_DIM,
        },
        file: {
            background: "linear-gradient(145deg, #ffffff, #f5f5f5)",
            borderRadius: 12,
            boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
            display: "flex",
            height: 80,
            width: "100%",
            position: "relative",
            zIndex: 10,
            flexDirection: "column",
            justifyContent: "center",
            transition: 'transform 0.2s ease',
            '&:hover': {
                transform: 'translateY(-2px)'
            }
        },
        info: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            padding: '4px 8px',
        },
        size: {
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            borderRadius: 3,
            marginBottom: '0.25em',
            justifyContent: "center",
            display: "flex",
            fontSize: '0.75rem',
        },
        name: {
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            borderRadius: 3,
            fontSize: '0.75rem',
            marginBottom: '0.25em',
        },
        progressBar: {
            bottom: 8,
            position: "absolute",
            width: "100%",
            paddingLeft: 10,
            paddingRight: 10,
        },
        remove: {
            height: 23,
            position: "absolute",
            right: 6,
            top: 6,
            width: 23,
        },
    };

    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [val, setVal] = useState([]);
    const [domains, setDomains] = useState("");
    const [registrar, setRegistrar] = useState("");
    const [isCSVUploaded, setIsCSVUploaded] = useState(false);
    const [excelUrl, setExcelUrl] = useState("");
    const [urlError, setUrlError] = useState("");
    const [uploadError, setUploadError] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [disabledRegistrar, setDisabledRegistrar] = useState(true);

    useEffect(() => {
        if (!isCSVUploaded && domains.length > 0) {
            const domainList = domains.split(/[,\n]/)
                .map(domainName => ({ domain: domainName.trim() }))
                .filter(domain => domain.domain !== '');
            setVal(domainList);
            setDisabled(false);
        }
    }, [domains, isCSVUploaded]);

    const isUploadEnabled = (isCSVUploaded || val.length > 0);

    const validateGoogleSheetsUrl = (url) => {
        const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/e\/[^\/]+\/pub\?output=csv$/;
        return regex.test(url);
    };

    const validateHeaders = (dataArray, requiredHeaders) => {
        if (dataArray.length === 0) return false;
        const firstRow = dataArray[0];
        return requiredHeaders.every(header => Object.hasOwnProperty.call(firstRow, header));
    };

    const handleExcelUrlChange = (e) => {
        const url = e.target.value;
        setExcelUrl(url);
        if (validateGoogleSheetsUrl(url)) {
            setUrlError('');
            setDisabled(false);
        } else if (url === '') {
            setUrlError('');
        } else {
            setUrlError('URL must be of the format: https://docs.google.com/spreadsheets/d/e/<YOUR-DOCUMENT-LINK>/pub?output=csv');
        }
    };

    const handleSync = async () => {
        if (!excelUrl || !validateGoogleSheetsUrl(excelUrl)) return;

        try {
            const response = await getGoogleSheetData(excelUrl);
            const csvData = response.data;

            const parsedData = await parseCSV(csvData);
            const requiredHeaders = ['domain'];
            const isValid = validateHeaders(parsedData, requiredHeaders);

            if (!isValid) {
                setUploadError('The CSV file is in the wrong format. It must contain "Domain" header.');
                setDisabled(true);
                return;
            }
            setUploadError('');
            setVal(parsedData);
            setDisabled(false);
        } catch (error) {
            console.error('Error fetching or parsing CSV data:', error);
        }
    };

    const handleClose = () => {
        setVal([]);
        setDomains("");
        setRegistrar("");
        setIsCSVUploaded(false);
        setExcelUrl("");
        setUrlError("");
        setUploadError("");
        setDisabled(true);
        setDisabledRegistrar(true);
        props.handleClose();
    };

    const handleUpload = () => {
        if (isUploadEnabled) {
          console.log("dfdfdfdf",val);
          console.log("Dfdfd",registrar);
            props.setRegistrar(registrar);
            props.setState(val);
            handleClose();
        }
    };

    const downloadTemplate = () => {
        const csvContent = `Domain,Add Date,Allocated To,Cost,EST,GDV,Sale Price,Reserve,Min Offer,Type,Category,SEDO Category,DAN Category,Tags,Registrar,WhoIs Email,Nameservers,Lander,Dan,Afternic,Sedo,Dls,WBY,ABY,EBY,Reg,Regx,SG,CPC,Platform,Date,Price,Renewal,Future Renewal,Transfer Price,Auth Code,Auto Renew
example1.com,2024-01-01,Not Allocated,1000,5000,10000,8000,6000,4000,Brandable,Technology,Software,Tech,Premium,GoDaddy,Private,[ns1.example.com],MakeOffer,Yes,Yes,Yes,Yes,2010,2012,2015,10,8,75,2.5,Afternic,2023-12-01,900,15,15,50,ABC123,Yes
example2.net,2024-01-02,Not Allocated,2000,7500,15000,12000,9000,6000,Generic,Finance,Banking,Finance,Featured,NameCheap,Namekart,[ns1.namecheap.com],BuyNow,No,DLS,MLS,No,2008,2010,2013,12,10,82,3.2,Dan,2023-12-02,1800,12,12,45,XYZ789,No`;

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'owned_domains_template.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Dialog 
            open={props.open} 
            onClose={handleClose} 
            fullWidth 
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                    overflow: 'hidden'
                }
            }}
        >
            <DialogTitle 
                sx={{ 
                    py: 2, 
                    px: 3,
                    typography: 'h6',
                    borderBottom: '1px solid #e0e0e0',
                    color: '#1976d2',
                    backgroundColor: '#fff'
                }}
            >
                Add {props.title}
            </DialogTitle>
            <DialogContent sx={{ p: 3, backgroundColor: '#f8f9fa' }}>
                <Stack 
                    direction="row" 
                    spacing={3}
                    divider={
                        <Divider 
                            orientation="vertical" 
                            flexItem 
                            sx={{ mx: 1 }}
                        />
                    }
                    sx={{
                        p: 3,
                        mt:3,
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
                    }}
                >
                    <Box width="50%" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant="subtitle1" sx={{mb: -1, fontWeight: 500, color: '#1976d2' }}>
                            Quick Add
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel id="registrar-select-label">Select Registrar</InputLabel>
                            <Select
                                labelId="registrar-select-label"
                                id="registrar-select"
                                value={registrar}
                                label="Select Registrar"
                                onChange={(event) => {
                                    setRegistrar(event.target.value);
                                    setDisabledRegistrar(event.target.value === "");
                                }}
                                sx={{
                                    height: '42px',
                                    backgroundColor: '#fff',
                                    fontSize: '0.875rem',
                                    '& .MuiSelect-select': {
                                        fontSize: '0.875rem'
                                    }
                                }}
                            >
                                {props.registrars.map((registrarOption, index) => (
                                    <MenuItem key={index} value={registrarOption.registrar}>
                                        {registrarOption.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            multiline
                            placeholder="example.com, example.net, example.org"
                            rows={6}
                            fullWidth
                            value={domains}
                            onChange={(event) => setDomains(event.target.value)}
                            disabled={isCSVUploaded}
                            size="small"
                            sx={{ 
                                '& .MuiOutlinedInput-root': { 
                                    fontSize: '0.875rem',
                                    backgroundColor: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#f8f9fa'
                                    }
                                }
                            }}
                        />
                    </Box>

                    <Box width="50%" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant="subtitle1" sx={{mb: -1, fontWeight: 500, color: '#1976d2' }}>
                            Import via CSV
                        </Typography>
                        <CSVReader
                            onUploadAccepted={(results) => {
                                setVal(results.data);
                                setZoneHover(false);
                                setIsCSVUploaded(true);
                                setDisabled(false);
                            }}
                            config={{
                                worker: false,
                                header: true,
                                transformHeader: (header) => header.toLowerCase().replace(/\s+/g, ''),
                            }}
                            onDragOver={(event) => {
                                event.preventDefault();
                                setZoneHover(true);
                            }}
                            onDragLeave={(event) => {
                                event.preventDefault();
                                setZoneHover(false);
                            }}
                            styles={{
                                ...styles,
                                zone: {
                                    ...styles.zone,
                                    height: '138px',
                                    border: `2px dashed ${GREY}`,
                                    borderRadius: '20px',
                                    padding: '8px',
                                    fontSize: '0.875rem'
                                }
                            }}
                        >
                            {({
                                getRootProps,
                                acceptedFile,
                                ProgressBar,
                                getRemoveFileProps,
                                Remove,
                            }) => (
                                <div
                                    {...getRootProps()}
                                    style={{
                                        ...styles.zone,
                                        height: '138px',
                                        ...(zoneHover && styles.zoneHover),
                                    }}
                                >
                                    {acceptedFile ? (
                                        <div style={styles.file}>
                                            <div style={styles.info}>
                                                <span style={styles.size}>
                                                    {formatFileSize(acceptedFile.size)}
                                                </span>
                                                <span style={styles.name}>{acceptedFile.name}</span>
                                            </div>
                                            <div style={styles.progressBar}>
                                                <ProgressBar />
                                            </div>
                                            <div {...getRemoveFileProps()} style={styles.remove}>
                                                <Remove />
                                            </div>
                                        </div>
                                    ) : (
                                        "Drop CSV file here or click to upload"
                                    )}
                                </div>
                            )}
                        </CSVReader>
                        <Button 
                            variant="outlined" 
                            onClick={downloadTemplate}
                            sx={{ 
                                height: '42px',
                                textTransform: 'none',
                                borderRadius: 1,
                                fontWeight: 500,
                                borderColor: '#1976d2',
                                color: '#1976d2',
                                '&:hover': {
                                    backgroundColor: 'rgba(25, 118, 210, 0.04)',
                                    borderColor: '#1976d2'
                                }
                            }}
                        >
                            Download Template
                        </Button>
                    </Box>
                </Stack>

                <Box
                    sx={{
                        mt: 3,
                        p: 3,
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
                    }}
                >
                    <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500, color: '#1976d2' }}>
                        Import via Google Sheet
                    </Typography>
                    <Stack direction='row' spacing={2} alignItems="center">
                        <TextField
                            size="small"
                            fullWidth
                            label="Excel Sheet URL"
                            variant="outlined"
                            value={excelUrl}
                            onChange={handleExcelUrlChange}
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: '42px',
                                    backgroundColor: '#fff'
                                }
                            }}
                        />
                        <Button 
                            variant="contained" 
                            disabled={disabled || isCSVUploaded} 
                            onClick={handleSync}
                            sx={{
                                height: '42px',
                                minWidth: '100px',
                                textTransform: 'none',
                                fontWeight: 500,
                                boxShadow: 'none',
                                '&:hover': {
                                    boxShadow: 'none'
                                }
                            }}
                        >
                            Sync
                        </Button>
                    </Stack>
                    {urlError && <Typography color='error' variant='caption' sx={{ pl: 1 }}>{urlError}</Typography>}
                    {uploadError && <Typography color='error' variant='caption' sx={{ pl: 1 }}>{uploadError}</Typography>}
                </Box>
            </DialogContent>
            <DialogActions 
                sx={{ 
                    py: 2, 
                    px: 3,
                    borderTop: '1px solid #e0e0e0',
                    backgroundColor: '#fff'
                }}
            >
                <Button 
                    onClick={handleClose}
                    variant="outlined"
                    sx={{ 
                        textTransform: 'none',
                        fontWeight: 500,
                        borderColor: '#1976d2',
                        color: '#1976d2',
                        '&:hover': {
                            borderColor: '#1976d2',
                            backgroundColor: 'rgba(25, 118, 210, 0.04)'
                        }
                    }}
                >
                    Cancel
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button 
                    disabled={!isUploadEnabled}
                    onClick={handleUpload}
                    variant="contained"
                    sx={{ 
                        textTransform: 'none',
                        fontWeight: 500,
                        px: 3,
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 'none'
                        }
                    }}
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
}