import { msalInstance } from "..";
import { appRoles } from "../msalConfig";



export function isDeveloper()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.Developer);
}

export function isAdmin()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.Admin)||isDeveloper();
}

export function isltdExpiredProcessor()
{
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.Admin)|| arr.includes(appRoles.LtdExpiredProcessor) || isDeveloper();
}

export function isManager()
{
    const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.Manager)||isDeveloper();
}

export function isLeadManager()
{
    const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.Admin)|| arr.includes(appRoles.LeadManager)|| isDeveloper();
}

export function isLeadEmailAgent()
{
    const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.Admin)|| arr.includes(appRoles.LeadManager)|| arr.includes(appRoles.LeadEmailAgent)|| isDeveloper();
}

export function isProcessor()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.Employee)||isDeveloper();
}
export function isSocialAgent()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.SocialAgent)||arr.includes(appRoles.SocialManager)||arr.includes(appRoles.Admin)||isDeveloper();
}

export function isSocialManager()
{
    const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.SocialManager)||arr.includes(appRoles.Admin)||isDeveloper();
}


export function isQA()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.QA)||isDeveloper();
}

export function canAddOwned()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.AddOwned)||arr.includes(appRoles.AddAll)||isDeveloper()||isAdmin();
}

export function canAddLtd()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.AddLtd)||arr.includes(appRoles.AddAll)||isDeveloper()||isAdmin();
}

export function canAddPD()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.AddPD)||arr.includes(appRoles.AddAll)||isDeveloper();
}

export function canPlaceOrdersAq()
{
    const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.PlaceOrdersAq)||isAdmin()||isDeveloper();
}

export function canAddAld()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.AddAld)||arr.includes(appRoles.AddAll)||isDeveloper();
}
export function canAddAll()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.AddAll)||isDeveloper();
}
export function canAllocateOwned()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.AllocateOwned)||arr.includes(appRoles.AllocateAll)||isDeveloper();
}

export function canAllocateAll()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.AllocateAll)||isDeveloper();
}

export function canAllocateLtd()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.AllocateLtd)||arr.includes(appRoles.AllocateAll)||isDeveloper();
}

export function isProspecterVisible()
{
    const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.ESManager)||arr.includes(appRoles.EDManager)||arr.includes(appRoles.EDAnalyst)||arr.includes(appRoles.ESAnalyst)||arr.includes(appRoles.Admin)||isDeveloper();
}

export function isAcquisitionCenterVisible()
{
    const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
return arr.includes(appRoles.ESManager)||arr.includes(appRoles.EDManager)||arr.includes(appRoles.Admin)||isDeveloper();
}

export function isRenewalCenterVisible()
{
    const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.Admin) || isDeveloper();
}

export function isEmailCenterVisible()
{
    const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.ESManager)||arr.includes(appRoles.EDManager)||arr.includes(appRoles.TopLeadEmailAgent)||arr.includes(appRoles.Admin)||isDeveloper();
}

export function isLeadCenterVisible()
{
    const arr=msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.LeadManager) || arr.includes(appRoles.LeadEmailAgent)||arr.includes(appRoles.Admin)||isDeveloper();
}


export function getUserName()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().username;
//console.log(arr)
return arr;
}

export function getName()
{
    //console.log(msalInstance.getActiveAccount())
const arr=msalInstance.getActiveAccount().name;
//console.log(arr)
return arr;
}

export function isESManager() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.ESManager) || isDeveloper();
}

export function isESAnalyst() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.ESAnalyst) || isDeveloper();
}

export function isEDManager() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.EDManager) || isDeveloper();
}

export function isEDAnalyst() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.EDAnalyst) || isDeveloper();
}

export function canViewPortfolio() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.Portfolio) || isDeveloper()||isAdmin();
}

