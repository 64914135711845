import React, { useEffect, useState, useMemo } from 'react';
import {
  Box, Stack, Tab, ThemeProvider, Typography, TextField, Button, Snackbar, Alert
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { theme } from "../Theme";
import { getPaginatedMasterData } from "../api";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import { InputAdornment } from '@mui/material';
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';

export default function MasterData() {
  const [domains, setDomains] = useState([]);
  const [tabValue, setTabValue] = useState('All');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchDate, setSearchDate] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [rowSelection, setRowSelection] = useState({});

  const listTypes = ['All', 'SN', 'DC', 'PD', 'DD', 'GD', 'RP'];

  useEffect(() => {
    fetchDomains();
  }, [pagination.pageIndex, pagination.pageSize, tabValue, searchDate, sorting, columnFilters, globalSearch]);

  const fetchDomains = () => {
    setLoading(true);

    // Transform filters into the expected format
    const transformedFilters = columnFilters.reduce((acc, filter) => {
      if (filter.id && filter.value) {
        acc[filter.id] = {
          operator: filter.operator || 'equals',
          value: filter.value,
          type: filter.type || 'text'
        };
      }
      return acc;
    }, {
      process_date: searchDate ? {
        operator: 'equals',
        value: searchDate,
        type: 'date'
      } : undefined
    });

    // Transform sorting into the expected format
    const transformedSorting = sorting.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    getPaginatedMasterData({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sort: JSON.stringify(transformedSorting),
      filter: JSON.stringify(transformedFilters),
      search: globalSearch,
      list_type: tabValue !== 'All' ? tabValue : ''
    })
      .then(res => {
        setDomains(res.data.content);
        setTotalElements(res.data.totalElements);
        setLoading(false);
        setSuccess(true);
      })
      .catch(err => {
        setLoading(false);
        setError(true);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const hiddenColumns = {
    domain: true,
    list_type: true,
    appraised_value: true,
    appraised_wholesale_value: true,
    search_volume_exact_term: true,
    search_cpc_exact_term: true,
    search_ad_competition_exact_term: true,
    pagerank: true,
    wayback_records: true,
    wayback_age: true,
    whois_create_date: true,
    dmoz: true,
    similar_sales: true,
    end_user_buyers: true,
    tld_count: true,
    tld_count_dev: true,
    // Now the false values
    tld: false,
    type: false,
    category: false,
    sld: false,
    words: false,
    language: false,
    language_probability: false,
    sld_length: false,
    word_count: false,
    search_results_term: false,
    search_results_sld: false,
    search_ads_term: false,
    search_ads_sld: false,
    search_suggestion_term: false,
    search_suggestion_sld: false,
    search_volume_broad_term: false,
    search_cpc_broad_term: false,
    search_ad_competition_broad_term: false,
    ad_cost_broad_term: false,
    ad_cost_exact_term: false,
    alexa_traffic_rank: false,
    alexa_link_popularity: false,
    altavista_link_popularity: false,
    google_link_popularity: false,
    yahoo_link_popularity: false,
    altavista_link_saturation: false,
    google_link_saturation: false,
    yahoo_link_saturation: false,
    overture_term: false,
    overture_tld: false,
    overture_sld: false,
    whois_registrar: false,
    whois_expire_date: false,
    whois_update_date: false,
    whois_ns1: false,
    whois_ns2: false,
    whois_age: false,
    drop_date: false,
    auction_price: false,
    auction_bidders: false,
    auction_join_date: false,
    auction_end_date: false,
    auction_url: false,
    auction_traffic: false,
    auction_revenue: false,
    auction_title: false,
    auction_description: false,
    adult: false,
    yahoo: false,
    trademark_type: false,
    trademark_term: false,
    trademark_company: false,
    trademark_risk: false,
    trademark_probability: false,
    has_num: false,
    has_dash: false,
    extensions_taken: false,
    traffic_estimate: false,
    parking_revenue_estimate: false,
    development_revenue_estimate: false,
    process_date: false,
  };

  const columns = useMemo(() => [
    { accessorKey: 'domain', header: 'Domain', size: 120 },
    { accessorKey: 'list_type', header: 'Type', size: 40 },
    { accessorKey: 'appraised_value', header: 'EST', size: 40 },
    { accessorKey: 'appraised_wholesale_value', header: 'ESW', size: 40 },
    { accessorKey: 'search_volume_exact_term', header: 'SV', size: 40 },
    { accessorKey: 'search_cpc_exact_term', header: 'CPC', size: 40 },
    { accessorKey: 'search_ad_competition_exact_term', header: 'COMP', size: 40 },
    { accessorKey: 'pagerank', header: 'PR', size: 40 },
    { accessorKey: 'wayback_records', header: 'WBR', size: 40 },
    { accessorKey: 'wayback_age', header: 'ABY', size: 40 },
    { accessorKey: 'whois_create_date', header: 'WBY', size: 40 },
    { accessorKey: 'dmoz', header: 'DMOZ', size: 40 },
    { accessorKey: 'similar_sales', header: 'SS', size: 40 },
    { accessorKey: 'end_user_buyers', header: 'EUP', size: 40 },
    { accessorKey: 'tld_count', header: 'REG', size: 40 },
    { accessorKey: 'tld_count_dev', header: 'REGD', size: 40 },
    // Now the false values
    { accessorKey: 'tld', header: 'TLD', size: 40 },
    { accessorKey: 'type', header: 'Type', size: 40 },
    { accessorKey: 'category', header: 'Category', size: 60 },
    { accessorKey: 'sld', header: 'SLD', size: 40 },
    { accessorKey: 'words', header: 'Words', size: 60 },
    { accessorKey: 'language', header: 'Lang', size: 60 },
    { accessorKey: 'language_probability', header: 'Lang Prob', size: 60 },
    { accessorKey: 'sld_length', header: 'SLD Len', size: 40 },
    { accessorKey: 'word_count', header: 'Word Count', size: 40 },
    { accessorKey: 'search_results_term', header: 'Search Res Term', size: 60 },
    { accessorKey: 'search_results_sld', header: 'Search Res SLD', size: 60 },
    { accessorKey: 'search_ads_term', header: 'Search Ads Term', size: 60 },
    { accessorKey: 'search_ads_sld', header: 'Search Ads SLD', size: 60 },
    { accessorKey: 'search_suggestion_term', header: 'Search Sug Term', size: 60 },
    { accessorKey: 'search_suggestion_sld', header: 'Search Sug SLD', size: 60 },
    { accessorKey: 'search_volume_broad_term', header: 'Search Vol Broad', size: 60 },
    { accessorKey: 'search_cpc_broad_term', header: 'Search CPC Broad', size: 60 },
    { accessorKey: 'search_ad_competition_broad_term', header: 'Ad Comp Broad', size: 60 },
    { accessorKey: 'ad_cost_broad_term', header: 'Ad Cost Broad', size: 60 },
    { accessorKey: 'ad_cost_exact_term', header: 'Ad Cost Exact', size: 60 },
    { accessorKey: 'alexa_traffic_rank', header: 'Alexa Rank', size: 40 },
    { accessorKey: 'alexa_link_popularity', header: 'Alexa Link Pop', size: 50 },
    { accessorKey: 'altavista_link_popularity', header: 'AltaVista Link Pop', size: 50 },
    { accessorKey: 'google_link_popularity', header: 'Google Link Pop', size: 50 },
    { accessorKey: 'yahoo_link_popularity', header: 'Yahoo Link Pop', size: 50 },
    { accessorKey: 'altavista_link_saturation', header: 'AltaVista Link Sat', size: 50 },
    { accessorKey: 'google_link_saturation', header: 'Google Link Sat', size: 60 },
    { accessorKey: 'yahoo_link_saturation', header: 'Yahoo Link Sat', size: 60 },
    { accessorKey: 'overture_term', header: 'Overture Term', size: 60 },
    { accessorKey: 'overture_tld', header: 'Overture TLD', size: 60 },
    { accessorKey: 'overture_sld', header: 'Overture SLD', size: 60 },
    { accessorKey: 'whois_registrar', header: 'Registrar', size: 60 },
    { accessorKey: 'whois_expire_date', header: 'Expire Date', size: 60 },
    { accessorKey: 'whois_update_date', header: 'Update Date', size: 60 },
    { accessorKey: 'whois_ns1', header: 'NS1', size: 40 },
    { accessorKey: 'whois_ns2', header: 'NS2', size: 40 },
    { accessorKey: 'whois_age', header: 'Whois Age', size: 40 },
    { accessorKey: 'drop_date', header: 'Drop Date', size: 40 },
    { accessorKey: 'auction_price', header: 'Auction Price', size: 40 },
    { accessorKey: 'auction_bidders', header: 'Auction Bidders', size: 40 },
    { accessorKey: 'auction_join_date', header: 'Auction Join Date', size: 50 },
    { accessorKey: 'auction_end_date', header: 'Auction End Date', size: 50 },
    { accessorKey: 'auction_url', header: 'Auction URL', size: 60 },
    { accessorKey: 'auction_traffic', header: 'Auction Traffic', size: 50 },
    { accessorKey: 'auction_revenue', header: 'Auction Revenue', size: 50 },
    { accessorKey: 'auction_title', header: 'Auction Title', size: 60 },
    { accessorKey: 'auction_description', header: 'Auction Desc', size: 40 },
    { accessorKey: 'adult', header: 'Adult', size: 40 },
    { accessorKey: 'yahoo', header: 'Yahoo', size: 40 },
    { accessorKey: 'trademark_type', header: 'TM Type', size: 50 },
    { accessorKey: 'trademark_term', header: 'TM Term', size: 50 },
    { accessorKey: 'trademark_company', header: 'TM Company', size: 60 },
    { accessorKey: 'trademark_risk', header: 'TM Risk', size: 50 },
    { accessorKey: 'trademark_probability', header: 'TM Prob', size: 40 },
    { accessorKey: 'has_num', header: 'Has Num', size: 40 },
    { accessorKey: 'has_dash', header: 'Has Dash', size: 40 },
    { accessorKey: 'extensions_taken', header: 'Ext Taken', size: 40 },
    { accessorKey: 'traffic_estimate', header: 'Traffic Est', size: 40 },
    { accessorKey: 'parking_revenue_estimate', header: 'Parking Rev Est', size: 50 },
    { accessorKey: 'development_revenue_estimate', header: 'Dev Rev Est', size: 50 },
    { 
      accessorKey: 'process_date', 
      header: 'Process Date', 
      size: 120,
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue());
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      },
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: domains,
    enableColumnActions: false,
    enableColumnFilters: true,
    enableSorting: true,
    enableStickyHeader: true,
    enableFilters: false,
    maxMultiSortColCount: 3,
    enableGlobalFilter: true,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    rowCount: totalElements,

    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading: loading,
      globalFilter: globalSearch,
      rowSelection
    },

    initialState: {
      density: 'compact',
      pagination: { pageSize: 50, pageIndex: 0 },
      sorting: [],
      columnVisibility: hiddenColumns,
    },

    muiPaginationProps: {
      rowsPerPageOptions: [50, 100, 150, 250, 500, 1000],
      showFirstButton: false,
      showLastButton: false,
    },

    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalSearch,
    onRowSelectionChange: setRowSelection,

    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },

    muiTableHeadCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        backgroundColor: '#FFC0CB',
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        fontSize: '0.7rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row' spacing={1} alignItems='center' width="100%">
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenFilterDialog(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
          }}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          //disabled
          startIcon={<SortIcon />}
          onClick={() => setOpenSortDialog(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Sort
        </Button>
        <Box flexGrow={1} />
        <TextField
          placeholder="Search domains..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSearch();
          }}
          size="small"
          sx={{
            minWidth: '100px',
            '& .MuiOutlinedInput-root': {
              height: '30px',
              fontSize: '0.875rem',
              backgroundColor: '#ffffff',
              borderRadius: '20px',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ fontSize: '1.2rem', color: '#8797A8', cursor: 'pointer' }}
                  onClick={handleSearch}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    ),
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2}>
          <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
            Master Data
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <TextField
            label="Search by Date"
            type="date"
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Stack>

        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList 
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {listTypes.map((listType) => (
                <Tab 
                  key={listType} 
                  label={listType}
                  value={listType}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {listTypes.map((listType) => (
            <TabPanel key={listType} value={listType} sx={{ padding: 1 }}>
              <MaterialReactTable table={table} />
            </TabPanel>
          ))}
        </TabContext>
        <MultiFilterDialog
          open={openFilterDialog}
          onClose={() => setOpenFilterDialog(false)}
          columns={columns}
          initialFilters={columnFilters}
          onApplyFilter={handleFilterApply}
        />
        <MultiSortDialog
          open={openSortDialog}
          onClose={() => setOpenSortDialog(false)}
          columns={columns}
          initialSorting={sorting}
          onApplySort={handleSortApply}
        />
      </Stack>
    </ThemeProvider>
  );
}
