import React, { useState, useEffect, useMemo } from 'react';
import { ThemeProvider, List, ListItem, ListItemText, Divider, Typography, Box, Card, CardContent, Stack, Select, TextField, Button, Badge, MenuItem, InputLabel, FormControl } from '@mui/material';
import { theme } from '../Theme';
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { getAllSocialEmployees, getSocialChildren, getSocialsCollectedData, getUserNTeams } from '../api'; // You need to implement this API function
import { getUserName, isAdmin } from '../msalService';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

const getDate7Day=()=>{
  let d=new Date(); d.setDate(d.getDate()-7); return dayjs(d);
  }

export default function Archive() {

  
  const [data, setData] = useState([]);
  const [cf, setCf] = useState(2);
  const [b, setB] = useState(false);
  const [children, setChildren] = useState([]);
  const oneWeekAgo=useMemo(getDate7Day,[])
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    domain: '',
    deadlineFrom: null,
    deadlineTo: null,
    dateOfAllocFrom: oneWeekAgo,
    dateOfAllocTo: null,
    status: 'All',
    list: '',
    socialAgent: 'All',
  });

  const handleFilterChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const handleFilterSubmit = () => {
    setB(!b);
    // Call the API with the filters
  };
//const username=useMemo(getUserName(),[]);
const username=getUserName();

useEffect(()=>{//console.log(user);
  if(isAdmin())
    getAllSocialEmployees().then((res)=>{setChildren(res.data); }).catch((err)=>{console.log(err)})
    else
    getSocialChildren().then((res)=>{setChildren(res.data); }).catch((err)=>{console.log(err)})
    },[])


  useEffect(() => {
    // Fetch the data when the component mounts
    
    getSocialsCollectedData(
      filters.domain,
      filters.deadlineFrom ? filters.deadlineFrom.format('YYYY-MM-DD') + ' 00:00' : null,
      filters.deadlineTo ? filters.deadlineTo.format('YYYY-MM-DD') + ' 24:59' : null,
      filters.dateOfAllocFrom ? filters.dateOfAllocFrom.format('YYYY-MM-DD') + ' 00:00' : null,
      filters.dateOfAllocTo ? filters.dateOfAllocTo.format('YYYY-MM-DD') + ' 24:59' : null,
      filters.status,
      filters.list,
      filters.socialAgent
    )
      .then((res) => {
        setData(res.data);
        console.log(res.data)
        setCf((prev)=>{let count = 0;
          for (let key in filters) {
            if (filters[key] !== null && filters[key] !== '' && filters[key] !== 'All') {
              count++;
            }
          }
          return count;});
      })
      .catch((err) => {
        console.error('Error fetching socials collected data:', err);
      });
     
  }, [b]);

  useEffect(() => {
    // Fetch the data when the component mounts
   
      // setCf((prev)=>{let count = 0;
      //   for (let key in filters) {
      //     if (filters[key] !== null && filters[key] !== '') {
      //       count++;
      //     }
      //   }
      //   return count;});
        console.log(filters)
  }, [filters]);

  const columns = useMemo(
    () => [
        {
            id: 'domain',
            header: 'Domain',
            accessorFn: (row) => {return row.presentDomain.domain;},

          },
          {
            id: 'deadline',
            accessorFn: (row) => {return (new Date(row.presentDomain.deadline)).toISOString().split('T')[0]},
            header: 'Deadline',
            size:90,maxSize:90

          },
          {
            id: 'dateOfAlloc',
            accessorFn: (row) => {return (new Date(row.dateOfAlloc)).toISOString().split('T')[0]},
            header: 'Allocation Date',
            size:90,maxSize:90

          },
      {
        accessorKey: 'status',
        header: 'Status',
      },
      {
        accessorKey: 'list',
        header: 'List',
        accessorFn: (row) => {return row.presentDomain.list},

      },
      {
        accessorKey: 'socialAgent',
        header: 'Social Agent',
        accessorFn: (row) => { return row.user.firstName+" "+row.user.lastName},

      },
    ],
    []
  );

  const renderDetailPanel = React.useCallback(({ row }) => {
    return (
      ((row.original.socialWork&&row.original.socialWork.topLeads&&row.original.socialWork.topLeads.length>0)?<Box sx={{ padding: 2 }}>
        
        <List disablePadding >
            <Stack direction='column' spacing={0.5}>
          {row.original.socialWork.topLeads.map((lead, index) => (
            <React.Fragment key={index}>
              <ListItem disableGutters disablePadding alignItems="flex-start" >
                <Card sx={{margin:0,width:'100%',padding:0}}>
                    <CardContent>
                        <Stack direction='column' spacing={2} justifyContent='space-between'>
                            <Stack direction='row' spacing={10} justifyContent='flex-start'>
                            <Stack direction='row' spacing={1} width={230} >
                                <Typography color='grey' variant='body2'>Lead: </Typography>
                                <Typography  variant='body2' >{lead.domain}</Typography>
                            </Stack>
                            <Stack direction='row' spacing={1} width={230} >
                                <Typography color='grey' variant='body2'>Copyright: </Typography>
                                <Typography  variant='body2' >{lead.copyright}</Typography>
                            </Stack>
                            <Stack direction='row' spacing={1} width={230} >
                                <Typography color='grey' variant='body2'>Description: </Typography>
                                <Typography  variant='body2' >{lead.description}</Typography>
                            </Stack>
                            <Stack direction='row' spacing={1} width={230} >
                               
                            </Stack>
                            </Stack>
                           { (lead.socials&&lead.socials.length>0)&&<Stack direction='row' spacing={10} justifyContent='flex-start'>
                           
                            {lead.socials.map((social, socialIndex) => (
                        <Stack direction='row' spacing={1} width={230} >
                        <Typography color='grey' variant='body2'>{social.platform}: </Typography>
                        <Typography  variant='body2' >{social.platform==='Linkedin'?social.employees+" employees":social.followers+" followers"}</Typography>
                    </Stack>
                      ))}
                            
                            </Stack>}
                        </Stack>
                    </CardContent>
                </Card>
                {/* <ListItemText
                  primary={`Lead: ${lead.lead}`}
                  secondary={
                    <React.Fragment>
                      {lead.socials.map((social, socialIndex) => (
                        <Typography
                          key={socialIndex}
                          component="span"
                          variant="body2"
                          color="text.primary"
                          display="block"
                        >
                          {social.platform}: {social.followers} followers, {social.employees} employees
                        </Typography>
                      ))}
                    </React.Fragment> 
                  }
                />*/}
              </ListItem>
              {/* {index < row.original.socialWork.topLeads.length - 1 && <Divider variant="inset" component="li" />} */}
            </React.Fragment>
            
          ))}
          </Stack>
        </List>
      </Box>:null)
    );
  }, []);

  const domainTable=  useMaterialReactTable({ columns:columns,
    data:data,
    renderDetailPanel:renderDetailPanel,
    muiDetailPanelProps: ({ row }) => ({
        sx: {
          backgroundColor:'#fcfcfa',padding:0
        },
      }),
    enableExpandAll:true,
    muiTableContainerProps:{
      sx: { maxHeight: 'calc(100vh - 64px)' }, // Adjust this value as needed
    }})

  return (
    <ThemeProvider theme={theme}>
         <Badge badgeContent={cf} color="primary">
      <Button onClick={() => setFilterOpen(!filterOpen)}>
        {filterOpen ? 'Hide Filters' : 'Show Filters'}
      </Button></Badge>
    {filterOpen && (
      <Stack direction='column' spacing={2} alignItems='center' width='100%'>
        <Stack direction='row' spacing={2}><TextField
          name="domain"
          size='small'
          label="Domain"
          value={filters.domain}
          onChange={handleFilterChange}
        /> <FormControl  sx={{  minWidth: 200,justifyContent:'start' }} size="small">
        <InputLabel id="demo-select-small-label">Status</InputLabel> <Select
        size='small'
          name="status"
          label="status"
          value={filters.status}
          onChange={handleFilterChange}
        >
          <MenuItem value='All'>{"All"}</MenuItem>
          <MenuItem value="not started">{"Not Started"}</MenuItem>
          <MenuItem value="submitted">{"Submitted"}</MenuItem>

        </Select>
        </FormControl>
      <TextField
      size='small'
        name="list"
        label="List"
        value={filters.list}
        onChange={handleFilterChange}
      />
     <FormControl  sx={{  minWidth: 200,justifyContent:'start' }} size="small">
      <InputLabel id="demo-select-small-label">Social Agent</InputLabel> <Select
      size='small'
        name="socialAgent"
        label="Social Agent"
        value={filters.socialAgent}
        onChange={handleFilterChange}
      >
        <MenuItem value='All'>{"All"}</MenuItem>
        <MenuItem value={getUserName()}>{"Me"}</MenuItem>
       {children&&children.map((child)=>{ 
              return <MenuItem value={child.email}>{child.firstName+" "+child.lastName}</MenuItem>
            })}
      </Select>
      </FormControl>
      </Stack>
      <Stack direction='row' spacing={2} >
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer  sx={{}} components={['DatePicker','DatePicker']}> <DatePicker
                        format='YYYY-MM-DD'
                        name="deadlineFrom"
                        slotProps={{ textField: { size: 'small' } }}
        sx={{color:'text.primary',fontWeight:'600',width:220}}
          label="Deadline From"
          value={filters.deadlineFrom}
          onChange={(newValue) =>
            handleFilterChange({
              target: { name: 'deadlineFrom', value: newValue },
            })
          }
        />
        <DatePicker 
                        format='YYYY-MM-DD'
                        name="deadlineTo"
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{color:'text.primary',fontWeight:'600',width:220}}

          label="Deadline To"
          value={filters.deadlineTo}
          onChange={(newValue) =>
            handleFilterChange({
              target: { name: 'deadlineTo', value: newValue },
            })
          }
        />
        </DemoContainer>
    </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer sx={{}} components={['DatePicker','DatePicker']}>
    
        <DatePicker
              format='YYYY-MM-DD'
              slotProps={{ textField: { size: 'small' } }}
              sx={{color:'text.primary',fontWeight:'600',width:220}}

          name="dateOfAllocFrom"
          label="Allocation Date From"
          value={filters.dateOfAllocFrom}
          onChange={(newValue) =>
            handleFilterChange({
              target: { name: 'dateOfAllocFrom', value: newValue },
            })
          }
        />
        <DatePicker
                      format='YYYY-MM-DD'
                      slotProps={{ textField: { size: 'small' } }}
                      sx={{color:'text.primary',fontWeight:'600',width:220}}

          name="dateOfAllocTo"
          label="Allocation Date To"
          value={filters.dateOfAllocTo}
          onChange={(newValue) =>
            handleFilterChange({
              target: { name: 'dateOfAllocTo', value: newValue },
            })
          }
        />
        </DemoContainer>
    </LocalizationProvider>
    </Stack>
        <Button onClick={handleFilterSubmit}>Apply Filters</Button>
      </Stack>
      )}
      <MaterialReactTable
             table={domainTable}

      />
    </ThemeProvider>
  );
}