import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import {
  Box, Typography, Stack, ThemeProvider, TextField, Button,
  Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Tab, Link, Tooltip,
  Switch, FormControlLabel, Select, MenuItem
} from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { theme } from '../Theme';

import { isAdmin, isESManager, isEDManager, canPlaceOrdersAq } from '../msalService';
import { updateAprBulk, approveRenewalAprBulk, orderRenewalDomainsBulk, zeroAndArchiveAprRenewalDomainsBulk, sendBackToRecoRenewalDomainBulk, getRenewalDecisionHubDomain } from '../api';

import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomToggleButton from '../Common/CustomToggleButton';
import RenewalTable from './RenewalTable';

function DecisionHub() {
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [totalElements, setTotalElements] = useState(0);

  const [tabValue, setTabValue] = useState('NEW');
  const [loading, setLoading] = useState(false);
  const [isNlDomain, setIsNlDomain] = useState(false);
  const [isZeroReco, setIsZeroReco] = useState(false);

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewAction, setPreviewAction] = useState('');
  const [selectedAction, setSelectedAction] = useState('');

  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [updatedAprDomains, setUpdatedAprDomains] = useState({});
  const [localAprValues, setLocalAprValues] = useState({});


  const listTypes = ['NEW', 'ACTIVE', 'EXPIRING', 'EXPIRED'];

  const getSnackbarMessage = (action) => {
    switch (action) {
      case 'Approval': return 'Domains approved!';
      case 'Order': return 'Domains ordered!';
      case 'ZeroArchive': return 'Domains zero archived!';
      case 'SendBack': return 'Domains sent back!';
      default: return `Domains ${action}d successfully`;
    }
  };

  useEffect(() => {
    if (tabValue) {
      fetchDecisionHubData(tabValue);
    }
  }, [tabValue, isNlDomain, isZeroReco, pagination, sorting, columnFilters, globalSearch]);

  const handleDataChange = useCallback(() => {
    fetchDecisionHubData(tabValue);
  }, [tabValue]);

  const fetchDecisionHubData = useCallback((
    tab,
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);
    let apiCall = getRenewalDecisionHubDomain;

    // Transform filters into the expected format
    const transformedFilters = filters.reduce((acc, filter) => {
      // Only add filter if it has valid id and operator
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    // Transform sorting into the expected format
    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    try {
      apiCall({
        isNlDomain,
        isZeroReco,
        domainState: tab.toLowerCase(),
        page: paginationRules.pageIndex,
        size: paginationRules.pageSize,
        filter: JSON.stringify(transformedFilters),
        sort: JSON.stringify(transformedSorting),
        search: searchQuery,
      })
        .then(response => {
          if (response && response.data) {
            // Transform the data if needed
            const transformedData = response.data.content.map(item => ({
              acquShortlistedDomain: {
                id: item.id,
                domain: item.domain,
                list: item.list,
                host: item.host,
                tld: item.tld,
                wby: item.wby,
                aby: item.aby,
                reg: item.reg,
                regx: item.regx,
                sg: item.sg,
                cpc: item.cpc,
                bids: item.bids,
                price: item.price,
                est: item.est,
                gdv: item.gdv,
                reco: item.reco,
                apr: item.apr,
                edate: item.edate,
                createdAt: item.createdAt,
                tld_count: item.tld_count,
                tld_count_dev: item.tld_count_dev,
                p: item.p,
                len: item.len,
                esw: item.esw,
                gdw: item.gdw,
                aprUnaprBy: item.aprUnaprBy,
                recoBy: item.recoBy,
                spr500: item.spr500,
                sprGdv: item.sprGdv,
                remarks: item.remarks,
                recoRemark: item.recoRemark,
                aprRemark: item.aprRemark
              },
              socialLeads: item.socialDomain?.socialWorks?.[0]?.topLeads?.map(lead => {
                const leadData = lead.correctedLead || lead;

                return {
                  id: leadData.id,
                  domain: leadData.domain,
                  domainSnapShotUrl: leadData.domainSnapShotUrl,
                  copyright: leadData.copyright,
                  description: leadData.description,
                  isIrrelevant: leadData.irrelevant ?? false,
                  missedStatus: leadData.missedStatus ?? 0,
                  code: leadData.code,
                  noSocialsAvailable: leadData.noSocialsAvailable ?? false,
                  isInfluencer: leadData.isInfluencer ?? false,
                  leadStatus: leadData.leadStatus ?? "active",
                  wrongSocialStatus: leadData.wrongSocialStatus ?? 0,
                  socials: lead.socials?.map(social => {
                    const socialData = social.correctedSocial || social;

                    return {
                      id: socialData.id,
                      platform: socialData.platform.toLowerCase(),
                      status: socialData.status,
                      followers: socialData.followers,
                      employees: socialData.employees,
                      url: socialData.url,
                      userFullName: socialData.userFullName,
                      isIrrelevant: socialData.irrelevant ?? false,
                      missedStatus: socialData.missedStatus ?? 0,
                      wrongSocialStatus: socialData.wrongSocialStatus ?? 0
                    };
                  }) || [],
                  email: lead.emailWorks?.[0]?.emails?.[0] ? {
                    id: lead.emailWorks[0].emails[0].id,
                    email: lead.emailWorks[0].emails[0].email,
                    name: lead.emailWorks[0].emails[0].name,
                    designation: lead.emailWorks[0].emails[0].designation,
                    linkedinUrl: lead.emailWorks[0].emails[0].linkedinUrl
                  } : null
                };
              }) || [],
              socialWorkId: item.socialDomain?.socialWorks?.[0]?.id || null,
              reviewRemark: item.socialDomain?.socialWorks?.[0]?.reviewRemark,
              noLeadsAvailable: item.socialDomain?.socialWorks?.[0]?.noLeadsAvailable,
              agents: {
                // uploader: currentMode === 'ed'
                //   ? (item.user ? {
                //     name: `${item.user.firstName} ${item.user.lastName}`,
                //     email: item.user.email,
                //     role: 'Uploader'
                //   } : null)
                //   : (item.aqShortlistings?.length > 0
                //     ? item.aqShortlistings.map(shortlisting => ({
                //       name: `${shortlisting.user.firstName} ${shortlisting.user.lastName}`,
                //       email: shortlisting.user.email,
                //       role: 'Shortlister'
                //     }))
                //     : []),
                processor: item.socialDomain?.socialWorks?.[0]?.user ? {
                  name: `${item.socialDomain.socialWorks[0].user.firstName} ${item.socialDomain.socialWorks[0].user.lastName}`,
                  email: item.socialDomain.socialWorks[0].user.email,
                  role: 'Processor'
                } : null,
                reviewer: item.socialDomain?.presentAllocation?.reviewer ? {
                  name: `${item.socialDomain.presentAllocation?.reviewer.firstName} ${item.socialDomain.presentAllocation?.reviewer.lastName}`,
                  email: item.socialDomain.presentAllocation?.reviewer.email,
                  role: 'Reviewer',
                  reviewStatus: item.socialDomain.presentAllocation.reviewStatus,
                  reviewRemark: item.socialDomain.presentAllocation.reviewRemark,
                  noLeadsAvailable: item.socialDomain.presentAllocation.noLeadsAvailable,
                } : null,
                recommender: item.recoBy ? {
                  name: typeof item.recoBy === 'string'
                    ? item.recoBy
                    : `${item.recoBy.firstName} ${item.recoBy.lastName}`,
                  email: item.recoBy.email,
                  role: 'Recommender'
                } : null,
                approver: item.aprUnaprBy ? {
                  name: item.aprUnaprBy,
                  role: 'Approver'
                } : null
              }
            }));
            setData(transformedData);
            setTotalElements(response.data.totalElements || 0);
          } else {
            console.error('Invalid API response format:', response);
            setData([]);
            setTotalElements(0);
          }
        })
        .catch(error => {
          console.error('API call failed:', error);
          setData([]);
          setTotalElements(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error in fetchDecisionHubData:', error);
      setLoading(false);
      setData([]);
      setTotalElements(0);
    }
  }, [isNlDomain, isZeroReco, columnFilters, sorting, pagination, globalSearch]);

  const handleAprChange = useCallback((id, value) => {
    const numValue = Number(value);
    if (!isNaN(numValue)) {
      setUpdatedAprDomains(prev => ({
        ...prev,
        [id]: numValue
      }));
    }
  }, []);

  const handleAprRemarkChange = useCallback((id, value) => {
    setUpdatedAprDomains(prev => ({
      ...prev,
      aprRemark: {
        ...prev.aprRemark,
        [id]: value
      }
    }));
  }, []);

  const handleActionChange = (event) => {
    const action = event.target.value;
    setSelectedAction(action);
    setPreviewAction(action);
    setPreviewDialogOpen(true);
  };

  const handleFinalSubmit = async () => {
    try {
      setLoading(true);
      const updatedDomains = previewData;
      let apiCall;
      switch (previewAction) {
        case 'Approval': apiCall = approveRenewalAprBulk; break;
        case 'Order': apiCall = orderRenewalDomainsBulk; break;
        case 'ZeroArchive': apiCall = zeroAndArchiveAprRenewalDomainsBulk; break;
        case 'SendBack': apiCall = sendBackToRecoRenewalDomainBulk; break;
        default: apiCall = updateAprBulk;
      }

      // Make sure the API call exists and returns a Promise
      if (typeof apiCall !== 'function') {
        throw new Error(`Invalid action: ${previewAction}`);
      }

      await apiCall(updatedDomains, previewAction);

      setRowSelection({});
      setUpdatedAprDomains({});
      setLocalAprValues({});
      setSelectedAction('');
      setSnackbar({
        open: true,
        message: getSnackbarMessage(previewAction),
        severity: 'success'
      });

      fetchDecisionHubData(tabValue);
    } catch (error) {
      console.error('Error updating domains:', error);
      setSnackbar({
        open: true,
        message: `Failed to ${previewAction.toLowerCase()} domains: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
      setPreviewDialogOpen(false);
    }
  };

  const handleZeroRecoToggle = (event) => {
    const newZeroRecoValue = event.target.checked;
    if (newZeroRecoValue && isNlDomain) {
      setIsNlDomain(false);
    }
    setIsZeroReco(newZeroRecoValue);
  };

  const handleNlDomainToggle = (event) => {
    const newNlDomainValue = event.target.checked;
    if (newNlDomainValue && isZeroReco) {
      setIsZeroReco(false);
    }
    setIsNlDomain(newNlDomainValue);
  };

  const previewColumns = useMemo(() => [
    {
      accessorKey: 'edate',
      header: 'edate',
      size: 50,
      accessorFn: (row) => {
        const date = row.edate;
        if (!date) return '-';
        const parsedDate = new Date(date);
        return isNaN(parsedDate.getTime()) ? '-' : parsedDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-');
      }
    },
    {
      accessorKey: 'createdAt',
      header: 'sdate',
      size: 50,
      accessorFn: (row) => {
        const date = row.createdAt;
        if (!date) return '-';
        const parsedDate = new Date(date);
        return isNaN(parsedDate.getTime()) ? '-' : parsedDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-');
      }
    },
    { accessorKey: 'list', header: 'list', size: 40 },
    { accessorKey: 'domain', header: 'domain', size: 100 },
    { accessorKey: 'p', header: 'p', size: 30 },
    { accessorKey: 'bids', header: 'bids', size: 60 },
    { accessorKey: 'price', header: 'price', size: 60 },
    { accessorKey: 'est', header: 'est', size: 60 },
    { accessorKey: 'gdv', header: 'gdv', size: 60 },
    { accessorKey: 'esw', header: 'esw', size: 60 },
    { accessorKey: 'gdw', header: 'gdw', size: 60 },
    {
      accessorKey: 'spr500',
      header: 'spr500',
      size: 60,
      Cell: ({ cell }) => `${(cell.getValue() * 100).toFixed(0)}%`
    },
    { accessorKey: 'reco', header: 'reco', size: 50 },
    { accessorKey: 'apr', header: 'apr', size: 50 },
  ], []);

  const previewData = useMemo(() => {
    const filteredData = (previewAction === 'Approval' || previewAction === 'Order')
      ? data.filter((item, index) =>
        rowSelection[index] &&
        (localAprValues[item.acquShortlistedDomain.id] > 0 || item.acquShortlistedDomain.apr > 0)
      )
      : data.filter((_, index) => rowSelection[index]);

    return filteredData.map(item => ({
      ...item.acquShortlistedDomain,
      apr: localAprValues[item.acquShortlistedDomain.id] ?? item.acquShortlistedDomain.apr,
      aprRemark: updatedAprDomains.aprRemark?.[item.acquShortlistedDomain.id] ?? item.acquShortlistedDomain.aprRemark
    }));
  }, [data, rowSelection, localAprValues, previewAction]);


  const previewTable = useMaterialReactTable({
    columns: previewColumns,
    data: previewData,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    enableTableHead: true,
    enableFilters: false,
    initialState: { density: 'compact' },
    enableColumnActions: false,
    enableColumnFilters: true,
    enableSorting: false,
    enableStickyHeader: true,
    enablePagination: false,

    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
        "& .MuiTableCell-root": {
          padding: "4px 8px",
          fontSize: "0.6rem",
        },
      }
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: '#000',
        fontWeight: 'bold',
        fontSize: '0.6rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.6rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '400px',
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30, 50, 100, 200, 300],
      showFirstButton: false,
      showLastButton: false,
    },
  });

  const handleKeyDown = (event, rowId, columnKey) => {
    const focusableColumns = ['apr', 'aprRemark'];
    if (event.key === 'Enter') {
      event.preventDefault();
      const currentRowIndex = data.findIndex(item => item.acquShortlistedDomain.id === rowId);
      const nextRow = data[currentRowIndex + 1];
      if (nextRow) {
        const nextInput = document.querySelector(`input[data-row-id="${nextRow.acquShortlistedDomain.id}"][data-column-key="${columnKey}"]`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else if (event.key === 'Tab') {
      event.preventDefault();
      const currentRowIndex = data.findIndex(item => item.acquShortlistedDomain.id === rowId);
      const currentColumnIndex = focusableColumns.indexOf(columnKey);
      const nextColumnKey = focusableColumns[(currentColumnIndex + 1) % focusableColumns.length];
      const nextInput = document.querySelector(`input[data-row-id="${rowId}"][data-column-key="${nextColumnKey}"]`);
      if (nextInput) {
        nextInput.focus();
      } else {
        // Move to first column of next row
        const nextRow = data[currentRowIndex + 1];
        if (nextRow) {
          const firstInputInNextRow = document.querySelector(`input[data-row-id="${nextRow.acquShortlistedDomain.id}"][data-column-key="${focusableColumns[0]}"]`);
          if (firstInputInNextRow) {
            firstInputInNextRow.focus();
          }
        }
      }
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'reco',
      header: 'reco',
      size: 40,
      minSize: 40,
      maxSize: 60,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          paddingLeft: '4px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.reco;
        return value !== null && value !== undefined ? value : '-';
      }
    },
    {
      accessorKey: 'apr',
      header: 'apr',
      size: 50,
      minSize: 50,
      maxSize: 60,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          padding: '1px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      accessorFn: (row) => row.acquShortlistedDomain.apr,
      Cell: ({ row }) => {
        const rowId = row.original.acquShortlistedDomain.id;
        const [localValue, setLocalValue] = useState(
          localAprValues[rowId] ?? row.original.acquShortlistedDomain.apr ?? ''
        );

        // Sync with external changes
        useEffect(() => {
          setLocalValue(localAprValues[rowId] ?? row.original.acquShortlistedDomain.apr ?? '');
        }, [rowId, localAprValues[rowId], row.original.acquShortlistedDomain.apr]);

        const handleChange = (e) => {
          const newValue = e.target.value;
          setLocalValue(newValue);
        };

        const handleBlur = () => {
          const numValue = Number(localValue);
          if (!isNaN(numValue)) {
            handleAprChange(rowId, numValue);
            setLocalAprValues(prev => ({
              ...prev,
              [rowId]: numValue
            }));
          }
        };

        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              size="small"
              type="number"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(e) => handleKeyDown(e, rowId, 'apr')}
              inputProps={{
                'data-row-id': rowId,
                'data-column-key': 'apr',
                min: 0,
                style: {
                  padding: '1px 4px',
                  width: '30px',
                  fontStyle: 'italic'
                },
                sx: { fontSize: 10, height: 16 }
              }}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                padding: 0,
                margin: 0,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                "& input[type=number]": { MozAppearance: "textfield" }
              }}
            />
          </Stack>
        );
      },
    },
    {

      accessorKey: 'aprRemark',
      header: 'aRemark',
      size: 80,
      minSize: 80,
      maxSize: 90,
      Cell: ({ row }) => {
        const rowId = row.original.acquShortlistedDomain.id;
        const [localValue, setLocalValue] = useState(
          updatedAprDomains.aprRemark?.[rowId] ??
          row.original.acquShortlistedDomain.aprRemark ??
          ''
        );

        // Sync with external changes
        useEffect(() => {
          setLocalValue(
            updatedAprDomains.aprRemark?.[rowId] ??
            row.original.acquShortlistedDomain.aprRemark ??
            ''
          );
        }, [rowId, updatedAprDomains.aprRemark, row.original.acquShortlistedDomain.aprRemark]);

        const handleChange = (e) => {
          setLocalValue(e.target.value);
        };

        const handleBlur = () => {
          handleAprRemarkChange(rowId, localValue);
        };

        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              size="small"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(e) => handleKeyDown(e, rowId, 'aprRemark')}
              inputProps={{
                'data-row-id': rowId,
                'data-column-key': 'aprRemark',
                style: {
                  padding: '1px 4px',
                  width: '60px',
                  fontStyle: 'italic'
                },
                sx: { fontSize: 10, height: 16 }
              }}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                padding: 0,
                margin: 0
              }}
            />
          </Stack>
        );
      }
    },
    {
      accessorKey: 'recoRemark',
      header: 'rRemark',
      size: 80,
      minSize: 80,
      maxSize: 80,
      Cell: ({ row }) => (
        <div className="px-2 py-1">
          {row.original.acquShortlistedDomain.recoRemark || ''}
        </div>
      )
    },
    {
      accessorKey: 'code',
      header: 'code',
      size: 30,
      minSize: 30,
      maxSize: 40,
      Cell: ({ row }) => {
        const initialCodeValue = useMemo(() => {
          const codeString = row.original.socialLeads.map(lead => lead.code || '').join('');
          return codeString || '-';
        }, [row.original.socialLeads]);
        return (
          <Typography
            variant="body2"
            sx={{
              fontSize: '0.7rem',
              fontStyle: 'italic',
              padding: '1px 4px',
            }}
          >
            {initialCodeValue}
          </Typography>
        );
      },
    },
  ], [updatedAprDomains, handleAprChange, localAprValues, handleAprRemarkChange, handleKeyDown, data]);


  const handleTabChange = (event, newValue) => {
    setRowSelection({});
    setLocalAprValues({});
    setPreviewAction('');
    setPreviewDialogOpen(false);
    setTabValue(newValue);
  };

  const columnVisibility = {
    agents: false,
    remarks: false,
    createdAt: false,
    recoRemark: false,
    aprRemark: false,
  }

  useEffect(() => {
    return () => {
      setRowSelection({});
      setLocalAprValues({});
    };
  }, [data]);

  const handleClosePreviewDialog = () => {
    setPreviewDialogOpen(false);
    setSelectedAction('');
  };

  const toolbarStyles = {
    select: {
      height: '34px',
      backgroundColor: '#8797A8',
      color: '#FFFFFF',
      '.MuiSelect-select': {
        padding: '4px 8px',
        fontSize: '0.85rem',
        color: '#FFFFFF',
      },
      '&.Mui-disabled': {
        backgroundColor: '#8797A8',
        opacity: 0.7,
      },
      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
      '&:hover': {
        backgroundColor: '#8797A8',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        opacity: 0.9,
      },
    },
    button: {
      minHeight: '34px',
      backgroundColor: '#8797A8',
      color: '#FFFFFF',
      padding: '4px 8px',
      fontSize: '0.85rem',
      textTransform: 'none',
      '&.MuiButton-outlined': {
        border: '1px solid #FFFFFF',
        '&:hover': {
          border: '1px solid #FFFFFF',
        },
      },
      '&:hover': {
        backgroundColor: '#8797A8',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        opacity: 0.9,
      },
      '&.Mui-disabled': {
        backgroundColor: '#8797A8',
        opacity: 0.7,
        color: '#FFFFFF',
      },
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%' >
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Decision Hub
          </Typography>
        </Stack>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {listTypes.map((listType) => (
                <Tab
                  key={listType}
                  label={listType}
                  value={listType}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {listTypes.map((listType) => (
            <TabPanel key={listType} value={listType} sx={{ padding: 1 }}>
              <Box>
                <RenewalTable
                  columns={columns}
                  data={data}
                  rowCount={totalElements}
                  isLoading={loading}
                  additionalColumns={columns}
                  columnVisibility={columnVisibility}
                  onDataChange={handleDataChange}

                  // Pass states
                  pagination={pagination}
                  sorting={sorting}
                  columnFilters={columnFilters}
                  globalSearch={globalSearch}
                  rowSelection={rowSelection}

                  // Pass state setters
                  setPagination={setPagination}
                  setSorting={setSorting}
                  setColumnFilters={setColumnFilters}
                  setGlobalSearch={setGlobalSearch}
                  setRowSelection={setRowSelection}

                  renderTopToolbarCustomActions={() => (
                    <Stack direction="row" spacing={0.5}>
                      <Select
                        size="small"
                        value={selectedAction}
                        onChange={handleActionChange}
                        displayEmpty
                        disabled={Object.keys(rowSelection).length === 0}
                        sx={toolbarStyles.select}
                      >
                        <MenuItem value="" disabled>
                          Select Action
                        </MenuItem>
                        <MenuItem value="Approval">Approve</MenuItem>
                        <MenuItem disabled={!canPlaceOrdersAq()} value="Order">Order</MenuItem>
                        <MenuItem value="ZeroArchive">Zero & Archive</MenuItem>
                        <MenuItem value="SendBack">Send Back</MenuItem>
                      </Select>
                      <CustomToggleButton
                        value="nl-domain"
                        selected={isNlDomain}
                        onChange={() => setIsNlDomain(!isNlDomain)}
                        label="NL"
                        tooltipText="NL-Domain"
                      />
                      <CustomToggleButton
                        value="0-reco"
                        selected={isZeroReco}
                        onChange={() => setIsZeroReco(!isZeroReco)}
                        label="0-R"
                        tooltipText="0-Reco"
                      />
                    </Stack>
                  )}
                />
              </Box>
            </TabPanel>
          ))}
        </TabContext>
        <Dialog
          open={previewDialogOpen}
          onClose={handleClosePreviewDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ fontSize: '1.1rem', padding: '12px 16px' }}>
            Preview {previewAction}
          </DialogTitle>
          <DialogContent sx={{ padding: '8px 16px' }}>
            <Box sx={{ overflowX: 'auto' }}>
              <MaterialReactTable table={previewTable} />
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: '8px 16px', justifyContent: 'space-between' }}>
            <Button onClick={handleClosePreviewDialog} size="small" sx={{ marginRight: 'auto' }}>
              Cancel
            </Button>
            <Button onClick={handleFinalSubmit} variant="contained" color="primary" size="small">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Stack>
    </ThemeProvider>
  );
}

export default DecisionHub;
