import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Typography, Stack, ThemeProvider, Button, Snackbar, Alert, Tab } from '@mui/material';
import { theme } from '../Theme';
import { getRenewalArchiveDomain} from '../api';
import { isAdmin, isESManager, isEDManager } from '../msalService';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RenewalTable from './RenewalTable';

export default function Archive() {
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [totalElements, setTotalElements] = useState(0);

  const [tabValue, setTabValue] = useState('NEW');
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const listTypes = ['NEW', 'ACTIVE', 'EXPIRING', 'EXPIRED'];

  useEffect(() => {
    if (tabValue) {
      fetchArchiveData(tabValue);
    }
  }, [tabValue, pagination, sorting, columnFilters, globalSearch]);

  const fetchArchiveData = useCallback((
    tab,
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);
    const apiCall = getRenewalArchiveDomain;

    // Transform filters into the expected format
    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    // Transform sorting into the expected format
    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    try {
      apiCall({
        domainState: tab.toLowerCase(),
        page: paginationRules.pageIndex,
        size: paginationRules.pageSize,
        filter: JSON.stringify(transformedFilters),
        sort: JSON.stringify(transformedSorting),
        search: searchQuery,
      })
        .then(response => {
          if (response && response.data) {
            const transformedData = response.data.content.map(item => ({
              acquShortlistedDomain: {
                id: item.id,
                domain: item.domain,
                list: item.list,
                host: item.host,
                tld: item.tld,
                wby: item.wby,
                aby: item.aby,
                reg: item.reg,
                regx: item.regx,
                sg: item.sg,
                cpc: item.cpc,
                bids: item.bids,
                price: item.price,
                est: item.est,
                gdv: item.gdv,
                reco: item.reco,
                apr: item.apr,
                edate: item.edate,
                createdAt: item.createdAt,
                tld_count: item.tld_count,
                tld_count_dev: item.tld_count_dev,
                p: item.p,
                len: item.len,
                esw: item.esw,
                gdw: item.gdw,
                aprUnaprBy: item.aprUnaprBy,
                recoBy: item.recoBy,
                spr500: item.spr500,
                sprGdv: item.sprGdv,
                remarks: item.remarks,
                status: item.status,
                activeStatus: item.activeStatus,
                recoRemark: item.recoRemark,
                aprRemark: item.aprRemark
              },
              socialLeads: item.socialDomain?.socialWorks?.[0]?.topLeads?.map(lead => {
                const leadData = lead.correctedLead || lead;

                return {
                  id: leadData.id,
                  domain: leadData.domain,
                  domainSnapShotUrl: leadData.domainSnapShotUrl,
                  copyright: leadData.copyright,
                  description: leadData.description,
                  isIrrelevant: leadData.irrelevant ?? false,
                  missedStatus: leadData.missedStatus ?? 0,
                  code: leadData.code,
                  noSocialsAvailable: leadData.noSocialsAvailable ?? false,
                  isInfluencer: leadData.isInfluencer ?? false,
                  leadStatus: leadData.leadStatus ?? "active",
                  wrongSocialStatus: leadData.wrongSocialStatus ?? 0,
                  socials: lead.socials?.map(social => {
                    const socialData = social.correctedSocial || social;

                    return {
                      id: socialData.id,
                      platform: socialData.platform.toLowerCase(),
                      status: socialData.status,
                      followers: socialData.followers,
                      employees: socialData.employees,
                      url: socialData.url,
                      userFullName: socialData.userFullName,
                      isIrrelevant: socialData.irrelevant ?? false,
                      missedStatus: socialData.missedStatus ?? 0,
                      wrongSocialStatus: socialData.wrongSocialStatus ?? 0
                    };
                  }) || [],
                  email: lead.emailWorks?.[0]?.emails?.[0] ? {
                    id: lead.emailWorks[0].emails[0].id,
                    email: lead.emailWorks[0].emails[0].email,
                    name: lead.emailWorks[0].emails[0].name,
                    designation: lead.emailWorks[0].emails[0].designation,
                    linkedinUrl: lead.emailWorks[0].emails[0].linkedinUrl
                  } : null
                };
              }) || [],
              socialWorkId: item.socialDomain?.socialWorks?.[0]?.id || null,
              reviewRemark: item.socialDomain?.socialWorks?.[0]?.reviewRemark,
              noLeadsAvailable: item.socialDomain?.socialWorks?.[0]?.noLeadsAvailable,
              agents: {
                // uploader: currentMode === 'ed'
                //   ? (item.user ? {
                //     name: `${item.user.firstName} ${item.user.lastName}`,
                //     email: item.user.email,
                //     role: 'Uploader'
                //   } : null)
                //   : (item.aqShortlistings?.length > 0
                //     ? item.aqShortlistings.map(shortlisting => ({
                //       name: `${shortlisting.user.firstName} ${shortlisting.user.lastName}`,
                //       email: shortlisting.user.email,
                //       role: 'Shortlister'
                //     }))
                //     : []),
                processor: item.socialDomain?.socialWorks?.[0]?.user ? {
                  name: `${item.socialDomain.socialWorks[0].user.firstName} ${item.socialDomain.socialWorks[0].user.lastName}`,
                  email: item.socialDomain.socialWorks[0].user.email,
                  role: 'Processor'
                } : null,
                reviewer: item.socialDomain?.socialWorks?.[0]?.reviewer ? {
                  name: `${item.socialDomain.socialWorks[0].reviewer.firstName} ${item.socialDomain.socialWorks[0].reviewer.lastName}`,
                  email: item.socialDomain.socialWorks[0].reviewer.email,
                  role: 'Reviewer',
                  reviewStatus: item.socialDomain.socialWorks[0].reviewStatus,
                  reviewRemark: item.socialDomain.socialWorks[0].reviewRemark,
                  noLeadsAvailable: item.socialDomain.socialWorks[0].noLeadsAvailable,
                } : null,
                recommender: item.recoBy ? {
                  name: typeof item.recoBy === 'string'
                    ? item.recoBy
                    : `${item.recoBy.firstName} ${item.recoBy.lastName}`,
                  email: item.recoBy.email,
                  role: 'Recommender'
                } : null,
                approver: item.aprUnaprBy ? {
                  name: item.aprUnaprBy,
                  role: 'Approver'
                } : null
              }
            }));
            setData(transformedData);
            setTotalElements(response.data.totalElements || 0);
          } else {
            console.error('Invalid API response format:', response);
            setData([]);
            setTotalElements(0);
          }
        })
        .catch(error => {
          console.error('API call failed:', error);
          setData([]);
          setTotalElements(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error in fetchArchiveData:', error);
      setLoading(false);
      setData([]);
      setTotalElements(0);
    }
  }, [columnFilters, sorting, pagination, globalSearch]);

  const handleDataChange = useCallback(() => {
    fetchArchiveData(tabValue);
  }, [tabValue]);

  const columns = useMemo(() => [
    {
      accessorKey: 'reco',
      header: 'reco',
      size: 50,
      minSize: 50,
      maxSize: 60,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8', // Plum color for 'reco' column
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          paddingLeft: '4px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      accessorFn: (row) => row.acquShortlistedDomain.reco || '-',
    },
    {
      accessorKey: 'apr',
      header: 'apr',
      size: 30,
      minSize: 30,
      maxSize: 40,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8', // Plum color for 'reco' column
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          padding: '1px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      accessorFn: (row) => row.acquShortlistedDomain.apr || '-',
    },
    {
      accessorKey: 'code',
      header: 'code',
      size: 30,
      minSize: 30,
      maxSize: 40,
      Cell: ({ row }) => {
          const initialCodeValue = useMemo(() => {
              const codeString = row.original.socialLeads.map(lead => lead.code || '').join('');
              return codeString || '-';
          }, [row.original.socialLeads]);
          return (
              <Typography
                  variant="body2"
                  sx={{
                      fontSize: '0.7rem',
                      fontStyle: 'italic',
                      padding: '1px 4px',
                  }}
              >
                  {initialCodeValue}
              </Typography>
          );
      },
    },
    {
      accessorKey: 'status',
      header: 'Domain Status',
      size: 120,
      minSize: 100,
      maxSize: 150,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#FFC0CB',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          padding: '4px',
          whiteSpace: 'nowrap',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
        }
        },
      },
      Cell: ({ row }) => {
        const domain = row.original.acquShortlistedDomain;
        let statusText = '';
        let statusColor = '';

        // Determine status based on conditions
        if (domain.status < 0) {
          if (domain.status === -1) {
            statusText = 'Rejected from Reco';
            statusColor = '#FF6B6B';
          } else if (domain.status === -2) {
            statusText = 'Rejected from Decision';
            statusColor = '#FF8787';
          }
        } else if (domain.status === 10) {
          statusText = 'Order Placed';
          statusColor = '#69DB7C';
        } else if (domain.activeStatus === 'archive') {
          if (domain.status === 1) {
            statusText = 'Expired from Reco';
            statusColor = '#FFD43B';
          } else if (domain.status === 2) {
            statusText = 'Expired from Decision';
            statusColor = '#FFA94D';
          } else if (domain.status === 3) {
            statusText = 'Expired from Order';
            statusColor = '#FF922B';
          }
        }

        return (
          <Typography
            variant="body2"
            sx={{
              fontSize: '0.7rem',
              color: statusColor,
              fontWeight: 'bold',
            }}
          >
            {statusText || '-'}
          </Typography>
        );
      },
    },
  ], []);

  const columnVisibility = {
    leads: false,
    socials: false,
    code: false,
    agents: false,
    active: false,
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%' >
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Archive
          </Typography>
          <Box flexGrow={1}></Box>
        </Stack>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {listTypes.map((state) => (
                <Tab
                  key={state}
                  label={state.charAt(0).toUpperCase() + state.slice(1)}
                  value={state}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {listTypes.map((state) => (
            <TabPanel key={state} value={state} sx={{ padding: 1 }}>
              <Box>
                <RenewalTable
                  columns={columns}
                  data={data}
                  rowCount={totalElements}
                  isLoading={loading}
                  additionalColumns={columns}
                  columnVisibility={columnVisibility}
                  onDataChange={handleDataChange}

                  // Pass states
                  pagination={pagination}
                  sorting={sorting}
                  columnFilters={columnFilters}
                  globalSearch={globalSearch}
                  rowSelection={rowSelection}

                  // Pass state setters
                  setPagination={setPagination}
                  setSorting={setSorting}
                  setColumnFilters={setColumnFilters}
                  setGlobalSearch={setGlobalSearch}
                  setRowSelection={setRowSelection}
                />
              </Box>
            </TabPanel>
          ))}
        </TabContext>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Stack>
    </ThemeProvider>
  );
}
