import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box, Typography, Stack, ThemeProvider, Button,
  Snackbar, Alert, Tab, Tooltip
} from '@mui/material';
import { Upload } from "@mui/icons-material";
import { theme } from '../Theme';
import { getOrderHubData, getEsOrderHubData, placeOrdersRenewalDomainsBulk, markOrderPlacedRenewalDomainsBulk, sendBackToAprRenewalDomainsBulk, fetchStats, getOrderHubDataPaginated, getEsOrderHubDataPaginated, getRenewalOrderHubDomain ,orderRenewalDomainsBulk} from '../api';
import { isAdmin, isESManager, isEDManager } from '../msalService';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AqTable from './AqTable';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';


function OrdersHub() {
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [totalElements, setTotalElements] = useState(0);

  const [tabValue, setTabValue] = useState('NEW');
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const listTypes = ['NEW', 'ACTIVE', 'EXPIRING', 'EXPIRED'];

  useEffect(() => {
    if (tabValue) {
      fetchOrderHubData(tabValue);
    }
  }, [tabValue, pagination, sorting, columnFilters, globalSearch]);

  const handleDataChange = useCallback(() => {
    fetchOrderHubData(tabValue);
  }, [tabValue]);

  const fetchOrderHubData = useCallback((
    tab,
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);
    let apiCall = getRenewalOrderHubDomain;

    // Transform filters and sorting into the expected format
    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    apiCall({
      domainState: tab.toLowerCase(),
      page: paginationRules.pageIndex,
      size: paginationRules.pageSize,
      filter: JSON.stringify(transformedFilters),
      sort: JSON.stringify(transformedSorting),
      search: searchQuery,
    })
      .then(response => {
        if (response && response.data) {
          const transformedData = response.data.content.map(item => ({
            acquShortlistedDomain: {
              id: item.id,
              domain: item.domain,
              list: item.list,
              host: item.host,
              tld: item.tld,
              wby: item.wby,
              aby: item.aby,
              reg: item.reg,
              regx: item.regx,
              sg: item.sg,
              cpc: item.cpc,
              bids: item.bids,
              price: item.price,
              est: item.est,
              gdv: item.gdv,
              reco: item.reco,
              apr: item.apr,
              edate: item.edate,
              createdAt: item.createdAt,
              tld_count: item.tld_count,
              tld_count_dev: item.tld_count_dev,
              p: item.p,
              len: item.len,
              esw: item.esw,
              gdw: item.gdw,
              aprUnaprBy: item.aprUnaprBy,
              recoBy: item.recoBy,
              spr500: item.spr500,
              sprGdv: item.sprGdv,
              remarks: item.remarks
            },
            socialLeads: item.socialDomain?.socialWorks?.[0]?.topLeads?.map(lead => {
              const leadData = lead.correctedLead || lead;

              return {
                id: leadData.id,
                domain: leadData.domain,
                domainSnapShotUrl: leadData.domainSnapShotUrl,
                copyright: leadData.copyright,
                description: leadData.description,
                isIrrelevant: leadData.irrelevant ?? false,
                missedStatus: leadData.missedStatus ?? 0,
                code: leadData.code,
                wrongSocialStatus: leadData.wrongSocialStatus ?? 0,
                leadStatus: leadData.leadStatus ?? "active",
                isInfluencer: leadData.isInfluencer ?? false,
                noSocialsAvailable: leadData.noSocialsAvailable ?? false,
                socials: lead.socials?.map(social => {
                  const socialData = social.correctedSocial || social;

                  return {
                    id: socialData.id,
                    platform: socialData.platform.toLowerCase(),
                    status: socialData.status,
                    followers: socialData.followers,
                    employees: socialData.employees,
                    url: socialData.url,
                    userFullName: socialData.userFullName,
                    isIrrelevant: socialData.irrelevant ?? false,
                    missedStatus: socialData.missedStatus ?? 0,
                    wrongSocialStatus: socialData.wrongSocialStatus ?? 0
                  };
                }) || [],
                email: lead.emailWorks?.[0]?.emails?.[0] ? {
                  id: lead.emailWorks[0].emails[0].id,
                  email: lead.emailWorks[0].emails[0].email,
                  name: lead.emailWorks[0].emails[0].name,
                  designation: lead.emailWorks[0].emails[0].designation,
                  linkedinUrl: lead.emailWorks[0].emails[0].linkedinUrl
                } : null
              };
            }) || [],
            socialWorkId: item.socialDomain?.socialWorks?.[0]?.id || null,
            reviewRemark: item.socialDomain?.socialWorks?.[0]?.reviewRemark,
            noLeadsAvailable: item.socialDomain?.socialWorks?.[0]?.noLeadsAvailable,
            agents: {
              // uploader: currentMode === 'ed'
              //   ? (item.user ? {
              //     name: `${item.user.firstName} ${item.user.lastName}`,
              //     email: item.user.email,
              //     role: 'Uploader'
              //   } : null)
              //   : (item.aqShortlistings?.length > 0
              //     ? item.aqShortlistings.map(shortlisting => ({
              //       name: `${shortlisting.user.firstName} ${shortlisting.user.lastName}`,
              //       email: shortlisting.user.email,
              //       role: 'Shortlister'
              //     }))
              //     : []),
              processor: item.socialDomain?.socialWorks?.[0]?.user ? {
                name: `${item.socialDomain.socialWorks[0].user.firstName} ${item.socialDomain.socialWorks[0].user.lastName}`,
                email: item.socialDomain.socialWorks[0].user.email,
                role: 'Processor'
              } : null,
              reviewer: item.socialDomain?.socialWorks?.[0]?.reviewer ? {
                name: `${item.socialDomain?.socialWorks?.[0]?.reviewer.firstName} ${item.socialDomain?.socialWorks?.[0]?.reviewer.lastName}`,
                email: item.socialDomain?.socialWorks?.[0]?.reviewer.email,
                role: 'Reviewer',
                reviewStatus: item.socialDomain?.socialWorks?.[0]?.reviewStatus,
                reviewRemark: item.socialDomain?.socialWorks?.[0]?.reviewRemark,
                noLeadsAvailable: item.socialDomain?.socialWorks?.[0]?.noLeadsAvailable,
              } : null,
              recommender: item.recoBy ? {
                name: typeof item.recoBy === 'string'
                  ? item.recoBy
                  : `${item.recoBy.firstName} ${item.recoBy.lastName}`,
                email: item.recoBy.email,
                role: 'Recommender'
              } : null,
              approver: item.aprUnaprBy ? {
                name: item.aprUnaprBy,
                role: 'Approver'
              } : null
            }
          }));
          setData(transformedData);
          setTotalElements(response.data.totalElements || 0);
        } else {
          console.error('Invalid API response format:', response);
          setData([]);
          setTotalElements(0);
        }
      })
      .catch(error => {
        console.error('API call failed:', error);
        setData([]);
        setTotalElements(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [columnFilters, sorting, pagination, globalSearch]);

  const handleSubmit = () => {
    setLoading(true);
    const selectedDomainIndices = Object.keys(rowSelection).reduce((acc, index) => {
      if (rowSelection[index]) {
        const domainId = data[index].acquShortlistedDomain.id;
        acc[domainId] = true;
      }
      return acc;
    }, {});
    placeOrdersRenewalDomainsBulk(selectedDomainIndices).then(res => {
      setSnackbar({ open: true, message: 'Orders placed successfully', severity: 'success' });
      setRowSelection({});
      fetchOrderHubData(tabValue);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Error placing orders', severity: 'error' });
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleMarkOrderPlaced = () => {
    setLoading(true);
    const selectedDomainIndices = Object.keys(rowSelection).reduce((acc, index) => {
      if (rowSelection[index]) {
        const domainId = data[index].acquShortlistedDomain.id;
        acc[domainId] = true;
      }
      return acc;
    }, {});
    markOrderPlacedRenewalDomainsBulk(selectedDomainIndices).then(res => {
      setSnackbar({ open: true, message: 'Orders marked placed successfully', severity: 'success' });
      setRowSelection({});
      fetchOrderHubData(tabValue);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Error marking placed orders', severity: 'error' });
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleSendBackToApr = () => {
    setLoading(true);
    const selectedDomainIndices = Object.keys(rowSelection).reduce((acc, index) => {
      if (rowSelection[index]) {
        const domainId = data[index].acquShortlistedDomain.id;
        acc[domainId] = true;
      }
      return acc;
    }, {});
    sendBackToAprRenewalDomainsBulk(selectedDomainIndices).then(res => {
      setSnackbar({ open: true, message: 'send Back successfully', severity: 'success' });
      setRowSelection({});
      fetchOrderHubData(tabValue);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Error sending back', severity: 'error' });
    }).finally(() => {
      setLoading(false);
    });
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'reco',
      header: 'reco',
      size: 50,
      minSize: 50,
      maxSize: 60,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          paddingLeft: '4px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      accessorFn: (row) => row.acquShortlistedDomain.reco || '-',
    },
    {
      accessorKey: 'apr',
      header: 'apr',
      size: 30,
      minSize: 30,
      maxSize: 40,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          padding: '1px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      accessorFn: (row) => row.acquShortlistedDomain.apr || '-',
    },
    {
      accessorKey: 'code',
      header: 'code',
      size: 30,
      minSize: 30,
      maxSize: 40,
      Cell: ({ row }) => {
          const initialCodeValue = useMemo(() => {
              const codeString = row.original.socialLeads.map(lead => lead.code || '').join('');
              return codeString || '-';
          }, [row.original.socialLeads]);
          return (
              <Typography
                  variant="body2"
                  sx={{
                      fontSize: '0.7rem',
                      fontStyle: 'italic',
                      padding: '1px 4px',
                  }}
              >
                  {initialCodeValue}
              </Typography>
          );
      },
    },
  ], []);

  const handleTabChange = (event, newValue) => {
    setRowSelection({});
    setTabValue(newValue);
  };

  const columnVisibility = {
    len: false,
    host: false,
    tld: false,
    wby: false,
    aby: false,
    reg: false,
    regx: false,
    sg: false,
    cpc: false,
    leads: false,
    socials: false,
    code: false,
    agents: false,
    active: false,
  }

  const toolbarStyles = {
    buttonSx: {
      textTransform: 'none',
      backgroundColor: '#8797A8',
      color: '#FFFFFF !important',
      padding: '4px 8px',
      minWidth: 'auto',
      '& .MuiButton-startIcon': {
        marginRight: '4px',
      },
      '&:hover': {
        backgroundColor: '#8797A8',
        opacity: 0.9,
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
      },
      '&.Mui-disabled': {
        backgroundColor: '#8797A8',
        opacity: 0.7,
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%' >
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Orders Hub
          </Typography>
          <Box flexGrow={1}></Box>
        </Stack>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {listTypes.map((listType) => (
                <Tab
                  key={listType}
                  label={listType}
                  value={listType}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {listTypes.map((listType) => (
            <TabPanel key={listType} value={listType} sx={{ padding: 1 }}>
              <Box>
                <AqTable
                  columns={columns}
                  data={data}
                  rowCount={totalElements}
                  isLoading={loading}
                  additionalColumns={columns}
                  columnVisibility={columnVisibility}
                  onDataChange={handleDataChange}

                  // Pass states
                  pagination={pagination}
                  sorting={sorting}
                  columnFilters={columnFilters}
                  globalSearch={globalSearch}
                  rowSelection={rowSelection}

                  // Pass state setters
                  setPagination={setPagination}
                  setSorting={setSorting}
                  setColumnFilters={setColumnFilters}
                  setGlobalSearch={setGlobalSearch}
                  setRowSelection={setRowSelection}

                  renderTopToolbarCustomActions={() => (
                    <Stack direction="row" spacing={0.5}>
                      <Button
                        size="small"
                        startIcon={<Upload />}
                        onClick={handleSubmit}
                        disabled={Object.keys(rowSelection).filter(key => rowSelection[key]).length === 0}
                        sx={toolbarStyles.buttonSx}
                      >
                        Place Orders
                      </Button>
                      <Button
                        size="small"
                        startIcon={<CheckRoundedIcon />}
                        onClick={handleMarkOrderPlaced}
                        disabled={Object.keys(rowSelection).filter(key => rowSelection[key]).length === 0}
                        sx={toolbarStyles.buttonSx}
                      >
                        Mark Placed
                      </Button>
                      <Button
                        size="small"
                        startIcon={<ReplyOutlinedIcon />}
                        onClick={handleSendBackToApr}
                        disabled={Object.keys(rowSelection).filter(key => rowSelection[key]).length === 0}
                        sx={toolbarStyles.buttonSx}
                      >
                        Send Back
                      </Button>
                    </Stack>
                  )}
                />
              </Box>
            </TabPanel>
          ))}
        </TabContext>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Stack>
    </ThemeProvider>
  );
}

export default OrdersHub;
